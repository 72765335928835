import { render, staticRenderFns } from "./companyLog.vue?vue&type=template&id=7cc5ed22&scoped=true&"
import script from "./companyLog.vue?vue&type=script&lang=js&"
export * from "./companyLog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cc5ed22",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
installComponents(component, {VBreadcrumbs,VBreadcrumbsItem})
